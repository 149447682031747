<template>
    <div>
        <div style="margin-top:20px">{{ $t("barcode.wel").format(this.brandConfig.display) }}</div>
        <img style="width:100%" id="barcode" />
        <p style="width:100%;margin:0;text-align: center;">{{`eForm ID - ${this.custId}`}}</p>
        <!-- <p style="width:100%;margin:0;text-align: center;">{{ $t("barcode.username").format(`${this.localLastName2} ${this.localFirstName2}`) }}</p> -->
        <div class="bind-msg-div" v-show="showBindConfirmMessage">{{ $t("barcode.bindConfirmMessage") }}</div>
    </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import mixins from "./mixin/Registration.js";
import { sendLineInfo } from "@/api/eformApi";
export default {
    name: "QRcodes",
    mixins: [mixins],
    data() {
        return {
            content: "test",
            custId: "",
            brandName: "",
            // storeId: "",
            lang: "",
            brand: "",
            // country: "",
            email: "",
            // country: "",
            localFirstName2: "",
            localLastName2: "",
            tpid: "",
            showBindConfirmMessage: false,
            bindConfirmMessage: this.$route.params.bindConfirmMessage
        };
    },
    created() {
        this.tpid = this.$route.params.tpid
        this.custId = this.$route.params.custId;
        this.storeId = this.$route.params.storeId;
        this.lang = this.$route.params.lang;
        this.brand = this.$route.params.brand;
        this.country = this.$route.params.countryPath;
        this.email = this.$route.params.email;
        this.localFirstName2 = this.$route.params.localFirstName2;
        this.localLastName2 = this.$route.params.localLastName2;
        if (this.$route.params.countryPath == 'JPN' && this.brandConfig.enableLine && this.tpid) {
            //post lineID and consumerID
            sendLineInfo({
                tpid: this.tpid,
                isOptin: this.$route.params.isOptin
            }).then(res => {
                if (res.success) {
                    this.showBindConfirmMessage = true;
                } else {
                    console.log(res.errMsg)
                }
            }).catch(err => {
                console.log(err)
            });
        }
        console.log("QRcodes", this)
    },
    destroyed() {
        // this.goBack();
    },
    mounted() {
        JsBarcode("#barcode", this.custId, {
            format: "CODE39",
            displayValue: false,
        });
    },
    methods: {
        goBack() {
            // this.$router.push(
            //     "/" +
            //         this.country +
            //         "/" +
            //         this.brand +
            //         "/Registration/" +
            //         this.lang +
            //         "?storeId=" +
            //         this.storeId
            // );

            this.$router.push(
                {
                    name: 'Registration',
                    params: {
                        lang: this.lang,
                        brand: this.brand,
                        country: this.country,
                        email: this.email,
                    },
                    query: {
                        storeId: this.storeId,
                    }
                }
            );
        },
    },
};
</script>

<style>
.bind-msg-div{
    margin-top: 20px;
}
</style>

